/*site colors*/
:root{
  --pwhite: #fbf9f7;
  --pwhite-50: #fbf9f7ad;
  --poffwht: #E9E2DF;
  --poffwht-50: #e9e2df6c;
  --pllbeet: #d7cbd2;
  --plbeet-50: #b1a0ab9c;
  --plbeet: #b1a0ab;
  --pbeet: #94195F;
  --pleaf: #6BAE87;
  --pleaf-25: #6bae875e;
  --pfrost: #417299;
  --pfrost-50: #4171996c;
  --pfrost-25: #41719943;
  --pgrass: #3B9277;
  --pgrass-50: #3b927779;
  --pgrass-25: #3b927744;
  --psage: #B1BCA0;
  --psage-50: #b1bca08d;
  --pmust: #B39516;
  --pmust-50: #b396167c;
  --pterra: #C45824;
  --pterra-50: #c459249b;
  --pterra-25: #c4592468;
  --ppaper: #B49575;
  --ppaper-75: #b49575cd;
  --ppaper-50: #b49575a8;
  --ppaper-25: #b4957541;
  --pcedar: #4B3A26;
  --lllgrey: #f2efef;
  --llgrey: #c7c5c5;
  --lgrey: #a6a5a5;
  --lgrey-50: #a6a5a57e;
  --lgrey-25: #a4b0a649;
  --mgrey: #656363;
  --dgrey: #3d3d3d;
  --lback: rgb(34, 34, 34);
  --ltomat: rgb(255, 99, 71);
}

/*set default base font size for em*/
html {
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background-color: var(--poffwht);
}

h1 {
  font-family: 'Fredoka One', serif;
  font-size: 2.5em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
}

h1.header-word {
  font-family: 'Averia Serif Libre', serif;
  font-size: 2em;
  font-weight: 700;
  color: var(--dgrey);
  margin-top: 0em;
  padding-top: 0.75em;
  padding-left: 1em;
}

h2 {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.6em;
  font-weight: 700;
  color: var(--pbeet);
  margin-top: 0em;
  padding-top: 1em;
}

h3 {
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.4em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
}

h4 {
  font-family: 'Cabin', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

h5 {
  font-family: 'Cabin', sans-serif;
  font-size: 1em;
  font-weight: 400;
  color: black;
  margin: 0em 0em;
}

h6 {
  font-family: 'Cabin', sans-serif;
  font-size: 0.75em;
  font-weight: 400;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

p {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
  line-height: 1.5;
}

a.blog-link {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.5em;
}

.footer-panel p {
  font-size: 0.75em;
}

ol{
  font-family:  'Cabin', sans-serif;
  color: black;
  margin: 0em 0em;
}

ol li::marker{
  font-size: 1.1em;
  font-weight: bold;
  color: var(--pgrass);
}

ol.home-text li::marker{
  font-size: 1em;
  color: var(--dgrey);
  font-weight: bold;
}

ol.home-text li{
  color: black;
  padding-bottom: 0.4em;
  text-align: left;
}

a:link, .plink {
  text-decoration: none;
  color: var(--pbeet);
}

.footer-list li a:link {
  text-decoration: none;
  color: var(--pcedar);
}

.footer-list li a:hover {
  color: var(--pterra);
}

.plink {
  margin: none;
}

a:hover, .plink:hover {
  color: var(--pmust);
}

a:active, .plink:active {
  color: var(--ltomat);
}

.subdomain {
  color: var(--pgrass);
  font-size: 0.9em;
}

/*FORMS*/
input[type=submit], input[type=button] {
  color: white;
  background-color: var(--pgrass);
  border-radius: 0.9em;
  border: 0 none;
  padding: 0.25em 0.5em;
  margin: 0.5em 0em;
  font-family: 'Fredoka One', serif;
  font-size: 1.25em;
  height: 2em;
  cursor: pointer;
  text-transform: lowercase;
  box-shadow: 0.1em 0.1em var(--lgrey-50);  
}

#login-inactive {
  background-color: var(--pwhite-50);
  border: 3px solid var(--pgrass);
  color: var(--mgrey);
}

button:active {
  background-color: var(--pmust);
}

#add {
  background-color: var(--pgrass);
  margin-right: 0.5em;
}

#cancel {
  background-color: var(--ltomat);
  margin-left: 0.5em;
}

input[type=submit]:hover, 
input[type=button]:hover, 
input#login-inactive:hover, 
input[type=button]#minor-button:hover, 
input[type=button]#add:hover,
input[type=submit]#add:hover,
input[type=button]#cancel:hover {
  background-color: var(--pmust);
  color: black;
  box-shadow: 0.2em 0.2em var(--lgrey);
}

input[type=submit]:focus {
  background-color: var(--ltomat);
  color: black;
  box-shadow: 0.25em 0.25em var(--ppaper);
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  border: 0.125em solid var(--lgrey);
  border-radius: 15%;
  transform: translateY(0.4em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--pbeet);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid var(--pleaf);
  outline-offset: max(2px, 0.15em);
}

input[disabled]{
  background-color: var(--lgrey-50);
}

#register-btn {
  margin-left: 0.5em;
}

.button-panel{
  margin-bottom: 0.5em;
}

.emoji {
  height: 0.8em;
  width: 0.8em;
  padding-left: 0.1em;
}

td h2 {
  padding-bottom: 0em;
  font-size: 1.5em;
  color: var(--pgrass);
}

th {
  text-transform: capitalize;
  font-family: 'Cabin', sans-serif;
  font-size: 1em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
  /*width: 5.75em;*/
  text-align: left;
  padding-left: 0.25em;
}

tr th {
  background-color: var(--lgrey-50);
  padding-right: 0.2em;
}

tr td{
  text-transform: capitalize;
  font-family: 'Cabin', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  color: var(--dgrey);
  margin-top: 0em;
  margin-bottom: 0em;
  vertical-align: middle;
  text-align: left;
  padding-left: 0.25em;
  height: 1.25em;
  max-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tr td.table-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.hide{
  display: none;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  border: 0.125em solid var(--lgrey);
  border-radius: 50%;
  transform: translateY(0.25em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--pbeet);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid var(--pleaf);
  outline-offset: max(2px, 0.15em);
}

/* input styling */
select {
  width: 19em;
  min-height: 2em;
}

input, select {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.warning {
  color: var(--ltomat);
  align-self: flex-start;
  padding-left: 0.5em;
}

section {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

/*popup forms mobile*/
@media screen and (max-width: 450px){
  img#hide-mobile{
    display: none;
  }
}

h3.motto {
  text-align: center;
}

label h6{
  padding-left: 0.5em;
}

label {
  display: flex;
  width: 16em;
  min-height: 2em;
}

.main-logo h6 {
  width: 50%;
  color: var(--dgrey);
  text-align: center;
}

.plannt {
  font-family: 'Fredoka One', serif;
  display: inline;
  font-size: 1em;
  font-weight: 400;
  color: var(--dgrey);
  margin-top: 0em;
}

.guess {
  display: inline-block;
  font-weight: 700;
  margin-top: 0em;
  color: var(--pfrost);
}

input::placeholder {
  color: var(--lgrey);
}

/*ICON*/
.icon {
  width: auto;
  height: 100%;
  overflow: hidden;
}

a .icon:hover {
  cursor: pointer;
}

.icon img {
  max-width: 100%;
  height: auto;
  display: block;
}

.full-page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0em;
  left: 0em;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  /* overflow-x: scroll; */
}

#full-view.full-page{
  /*padding: 0;*/
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.home-page{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.home-page::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.socials{
  width: 35px;
  height: 35px;
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.veg-icons{
  width: 50px;
  height: 50px;
  padding-bottom: 1em;
  padding-top: 0.75em;
}

#demo {
  display: flex;
  flex-direction: column;
}

#youtube {
  max-width: 90vw;
}

#subtext{
  color: var(--mgrey);
}

.top-bar{
  height: 6.5em;
  width: 100%;
  background-color: var(--poffwht);
  display: flex;
  flex-flow: row;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 10;
}

.top-container{
  width: 96%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10;
}

.left-top-container{
  width: auto;
  /*min-width: 20em;*/
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
}

.top-menu-button{
  height: 2.5em;
  min-width: 7em;
  background-color: var(--lgrey-50);
  border-radius: 1em;
  margin-left: 0.5em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;
}

.top-menu-burger{
  height: 3.5em;
  width: auto;
  background-color: var(--poffwht);
  border: 0 none;
  margin-left: 0.5em;
  padding: 0.5em 0.5em;
  border-radius: 1em;
  display: none;
}

.top-menu-burger:hover{
  background-color: var(--pgrass-25);
}

.top-menu-burger img{
  height: 100%;
  width: auto;
}

.top-menu-panel{
  display: none;
  padding-inline-start: 0;
}

.top-menu-panel.expanded{
  width: 16em;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.75em;
}

/*home screen for mobile*/
@media screen and (max-width: 450px){
  .top-menu-burger{
    display: block;
  }
  
  .top-menu-panel{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 9.5em;
    left: 0.75em;
    width: 12em;
    height: 6.5em;
    background-color: var(--poffwht);
    border-radius: 0 1em 1em 1em;
    padding-bottom: 0.25em;
  }

  .top-menu-panel .top-menu-button{
    margin-bottom: 0.5em;
    margin-right: 0.75em;
  }
  
  .top-menu-panel.expanded{
    display: none;
  }
}

.top-menu-button:hover {
  background-color: var(--pmust-50);
}

.top-button-selected{
  background-color: var(--pgrass-50);
}

.top-menu-button a{
  font-family: 'Averia Serif Libre', serif;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  text-align: center;
  font-weight: 600;
}

.dropdown{
  width: 15%;
  height: auto;
  margin-right: 0.5em;
}

.logo-text-home {
  font-size: 1.2em;
  padding-top: 0em;
  margin-top: 0em;
}

.home-button-panel{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 11em;
  padding-right: 1.5em;
}

.home-info-panel {
  padding-top: 6em;
  background-image: linear-gradient(to bottom, var(--poffwht), var(--pwhite));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  height: 100%;
}

.trial-text{
  font-family: 'Cabin', sans-serif;
  width: auto;
  color: var(--pbeet);
  font-size: 0.9em;
  padding-top: 0em;
  margin-top: 0em;
  font-weight: 600;
}

.footer-span{
  width: 100%;
  min-height: 3em;
  height: auto;
  max-height: 8em;
  background-color: var(--psage);
  overflow: hidden;
}

.footer-panel{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.5em;
  gap: 0.5em 0em;
}

.footer-list{
  list-style-type: none;
}

#icon-row {
  display: flex;
  flex-direction: row;
  gap: 0em 1em;
  margin: 0;
  padding-left: 1em;
  max-width: 14em;
}

#word-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row nowrap;
  min-width: 20em;
  justify-content: space-around;
  align-items: center;
}

.footer-list li{
  font-family: 'Averia Serif Libre', serif;
  font-size: 1em;
  font-weight: 500;
  color: var(--dgrey);
  margin: 0em;
  padding: 0em;
}

video{
  z-index: 1;
  width: 100vw;
  max-width: 35em;
}

#band{
  background-color: var(--plbeet-50);
  display: flex;
  align-items: center;
}

.home-row{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
}

.center-row {
  justify-content: center;
}

.spread-row{
  justify-content: flex-start;
}

.home-text-row{
  padding-bottom: 0.75em;
}

.home-text{
  padding: 1em;
  max-width: 50em;
}

#about-row .side-info{
  padding-top: 3em;
}

.home-col{
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-bottom: 1.5em;
  padding-top: 1.5em;
  margin-top: 0.5em;
}

.wide-img{
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.main-message{
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  max-height: 34em;
  background-color: var(--plbeet-50);
  justify-content: space-between;
  overflow: hidden;
}

.home-end{
  width: 100%;
  min-height: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0em;
}

.top-banner-left{
  min-width: 20em;
  max-width: 50em;
  margin-left: 5%;
  padding: 2em 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.top-banner-right{
  display: flex;
  flex-direction: row;
  width: 42%;
  margin-right: 0em;
  justify-content: flex-end;
  overflow: hidden;
}

.wide-left{
  border: 2px dashed red;
  min-width: 12em;
  min-height: 15em;
  height: 25em;
  width: 60%;
  margin-left: 0em;
  margin-right: 0em;
}

@media screen and (max-width: 1280px){
  div.wide-left {
    width: 50%;
  }
}

@media screen and (max-width: 1070px){
  div.wide-left {
    width: 40%;
  }
}

@media screen and (max-width: 940px){
  div.wide-left {
    width: 95%;
  }

  div.narrow-right {
    margin-left: 0;
    margin-bottom: 1em;
  }

  div.spread-row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
  }
}

.side-info{
  width: 100%;
  height: 100%;
  max-height: 24em;
  padding: 1.5em;
  overflow-y: hidden;
  flex-shrink: 1;
}

.narrow-right{
  border: 2px dashed blue;
  min-width: 6em;
  max-width: 24em;
  min-height: 15em;
  height: 25em;
  margin-left: 1em;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.spread-img{
  object-fit: cover;
  min-width: 20em;
  height: 100%;
  overflow: hidden;
}

.squash-img{
  object-fit: cover;
  min-width: 0em;
  max-width: 34em;
  height: 100%;
  overflow: hidden;
  align-self: flex-end;
}

.demo-img{
  min-width: 10em;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1000px){
  img.squash-img{
    display: none;
  }

  img.demo-img{
    object-fit: cover;
    object-position: 50% 50%;
  }

  .top-banner-right{
    display: none;
  }
}

.shd{
  background-color: var(--ppaper-25);
  padding-right: 0;
  margin-right: 0;
  justify-content: space-between;
}

#starter{
  margin-top: 4em;
}

.step{
  width: 100%;
  color: var(--pbeet);
  font-size: 2em;
}

.step-blurb{
  max-width: 22em;
  font-family: 'Cabin', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

.step-img{
  width: auto;
  height: 4em;
}

/*highlight reel*/
.home-grid {
  display: flex;
  gap: 2em 3em;
  width: 90%;
  margin: 0 auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.highlight-box {
  background-color: var(--pwhite-50);
  border: 2px solid var(--lgrey);
  border-radius: 1em;
  width: 20em;
  height: 22em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  box-shadow: 8px 8px 2px var(--ppaper-50);
  overflow: hidden;
}

.highlight-pic {
  margin-top: auto;
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.highlight-pic img{
  width: 100%;
  height: auto;
  margin-bottom: 50%;
  transform: translateY(-28%);
}

.highlight-box h3 {
  width: 95%;
  text-align: center;
  color: var(--lback);
  font-size: 1.8em;
  padding: 0.75em 0em 0.5em 0em;
}

.highlight-box h4 {
  width: 90%;
  color: var(--dgrey);
  text-align: center;
  padding: 0em 1em 0em 1em;
}

/*about*/
.about-box {  
  width: 20em;
  height: 24em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  overflow: hidden;
}

.about-box h3 {
  text-align: center;
  padding-top: 1em;
}

.about-box h4 {
  width: 90%;
  color: var(--mgrey);
  font-size: 1em;
  text-align: center;
  padding: 0em 1em 0em 1em;
}

.about-pic {
  border-radius: 50%;
  margin-top: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.about-pic img{
  width: 100%;
  height: auto;
  margin-bottom: 50%;
  /*transform: translateY(-28%);*/
}

.logo-panel {
  /*background-image: linear-gradient(to bottom, var(--poffwht-50), var(--pwhite));*/
  background-color: var(--poffwht-50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-top: 0em;
  width: 100%;
  /*padding: 2em 2em;*/
  height: 100%;
}

.logo-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.25em;
  height: 8em;
  min-height: 6em;
}

.logo-compact h1{
  font-size: 2em;
  padding-bottom: 0em;
  margin-bottom: 0.5em;
}

.big-motto{
  font-size: 2.75em;
  text-align: left;
  margin-bottom: 0.5em;
  font-family: 'Averia Serif Libre', serif;
  font-weight: 400;
  color: black;
}

.home-blurb{
  line-height: 1.3em;
  width: 100%;
  max-width: 40em;
  padding-bottom: 1em;
  font-family: 'Cabin', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  color: black;
  margin-top: 0em;
  margin-bottom: 0em;
}

.shift-right{
  text-align: right;
  padding-right: 20%;
  padding-bottom: 0.5em;
}

.separation-word{
  padding-top: 2.5em;
  padding-bottom: 0em;
  color: var(--pbeet);
  font-size: 2.5em;
  text-align: center;
}

.left {
  text-align: left;
}

.right-panel{
  background-color: var(--psage);
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  width: 80em; /*was 50em*/
  margin-top: 0em;
}

.login {
  background-color: var(--pwhite-50);
  border: 2px solid var(--pbeet);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 24em;
  max-width: 92%;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 0.5em 0.5em var(--plbeet);
  margin-bottom: 3em;
  padding: 0em;
}

.register {
  background-color: var(--pwhite-50);
  border: 2px solid var(--pbeet);
  display: flex;
  flex-direction: column;
  align-items: center; /*left with offset?*/
  justify-content: top;
  width: 24em;
  max-width: 93%;
  height: auto;
  border-radius: 0.5em;
  box-shadow: 0.5em 0.5em var(--plbeet);
  padding: 0em;
  margin-bottom: 3em;
}

/* NAVBAR */
.icon-top-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 7.5em;
  padding-top: 1.5em;
  padding-bottom: 0.25em;
  padding-left: 0em;
}

#topleft-icon {
  margin-top: 0.25em;
  min-height: 3.5em;
}

#bottom-icon {
  min-height: 6.5em;
}

.tm-box{
  position: relative;
}

.TM {
  position: absolute;
  z-index: 5;
  right: -1em;
  top: 0.25em;
  font-family: arial;
  font-size: 0.5em;
  color: var(--lgrey)
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  align-self: stretch;
  width: 12em;
  min-width: 12em;
  margin-top: 0em;
  overflow: hidden;
  background-color: var(--poffwht);
}

.navBar {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 80%;
  min-height: 30em;
  position: relative;
}

/* Links inside the navbar */
.navBar #nav-list {
  margin: 0em 0em;
  padding: 0em 0em;
  width: 100%;
}

.nav-top {
  position: absolute;
  top: 1.5em;
  left: 0;
}

.nav-bottom {
  position: absolute;
  bottom: 2em;
  left: 0;
}

.nav-list-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%;
  height: 3em;
  list-style-type: none;
  color: var(--dgrey);
  padding-left: 0em;
  padding-top: 0.25em;
}

#logout {
  cursor: pointer;
  border: none;
  background-color: var(--poffwht);
  width: 100%;
  padding: 0; 
  height: 3.25em;
}

#logout-text {
  font-size: 2em;
  text-align: left;
  padding-top: 0.1em;
  padding-left: 0.1em;
}

/*ICON PICS*/
.nav-icon, .light-nav-icon {
  height: 2.5em;
  width: auto;
  padding-right: 0.75em;
  padding-left: 0.5em;
  padding-top: 0.25em;
}

.acct-icon {
  height: 1.75em;
  width: auto;
  padding-left: 0.5em;
  padding-right: 5em;
}

.yard-icon {
  height: 2.25em;
  width: auto;
  padding: 0em;
  margin: 0em;
}

.nav-text {
  color: var(--dgrey);
  font-size: 1.75em;
  text-transform: lowercase;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-top: 0.2em;
}

.nav-list-item:hover {
  background-color: var(--pgrass-50);
  color: var(--pwhite);
  border-left: 4px solid var(--lback);
}

.active .nav-list-item {
  background-color: var(--pgrass);
}

.active .nav-icon {
  display: none;
}

.inactive .light-nav-icon {
  display: none;
}

.active .nav-text {
  color: var(--pwhite);
}

#logout-img {
  padding-left: 1.1em;
}

/*nav for mobile*/
@media screen and (max-width: 450px){
  .logo-text-home {
    font-size: 1em;
  }

  img#topleft-icon {
    max-width: 2em;
    height: auto;
    display: block;
  }

  div.main-message {
    padding-top: 6em;
  }
  
  img.nav-icon {
    padding-right: 0.1em;
    padding-left: 0.1em;
  }

  div.icon-top-left {
    padding-left: 0em;
    height: 6.5em;
  }

  #nav-icon h1{
    display: none;
  }

  #nav-icon{
    height: 4em;
    width: 3em;
  }

  #nav-icon img{
    height: 3em;
    width: auto;
  }

  img#logout-img{
    padding-top: 0.5em;
    padding-left: 0.4em;
  }

  .full-page{
    flex-direction: column;
    padding-top: 5em;
    overflow: auto;
  }

  .nav-text{
    display: none;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 4.5em;
    padding: 0;
    margin: 0;
    background-color: var(--poffwht);
    align-content: center;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    min-height: 4em;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  #nav-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .nav-top {
    height: 100%;
    position: relative;
    top: 1em;
    right: 10em;
    max-width: 14em;
  }
  
  .nav-bottom {
    height: 100%;
    position: relative;
    top: 1em;
    right: -0.25em;
    max-width: 7em;
  }

  .nav-list-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: 3em;
    height: 100%;
    list-style-type: none;
    color: var(--dgrey);
    padding-left: 0em;
    padding-right: 0.5em;
  }

  div.home-button-panel {
    padding: 0;
    margin: 0;
  }

  div.tutorial-header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    min-height: 6.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  div.footer-span{
    min-height: 7.5em;
  }

  /*demo screenshots*/
  div.spread-row {
    align-items: center;
  }

  div.wide-left{
    height: 17em;
    width: 90vw;
  }

  p.home-text{
    padding: 0.5em;
  }

  h2.home-blurb, h1.big-motto {
    width: 81vw;
  }

  div.main-page{
    width: 100%;
  }
}

.main-page{
  background-color: var(--pwhite);
  display: flex;
  flex-direction: column;
  align-items: left;
  align-self: stretch;
  justify-content: top;
  width: 88%;
}

.tutorial-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 110em;
  min-height: 5.5em;
  background-color: var(--lllgrey);
  overflow-y: hidden;
  overflow-x: hidden;
  padding-left: 0.5em;
  padding-right: 0.5em;
  box-sizing: border-box;
}

#scroll-header{
  overflow-x: scroll;
  min-height: 6em;
}

.header-icon {
  height: 2em;
  width: auto;
}

.header-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  /*border: 2px solid black;*/
  width: 11em;
  min-height: 3em;
  margin-left: 0.5em;
}

.page-container{
  width: 100%;
  overflow-y: scroll;
}

.page-content {
  padding-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
  min-height: 42em;
}

/*seeds for mobile*/
@media screen and (max-width: 450px){
  div.main-page{
    height: 100%;
    min-height: 50em;
  }

  .page-content{
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 2em;
  }

  .page-content .seeds-list{
    justify-content: center;
  }

  div.yard-page-content {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  div.yard-page-container {
    margin-bottom: 2em;
    height: 85vh;
  }

  div#scroll-header.tutorial-header{
    position: fixed;
    top: 4.5em;
    z-index: 4;
  }

  div.yard-button-container {
    left: 0em;
    top: 11.85em;
  }

  div.help-tip p{    /* The tooltip */
    width: 10em;
    left: -8em;
  }

  div.help-tip p:before{ /* The pointer of the tooltip */
    left:8.4em;
    top:-12px;
  }
}

.page-footer{
  position: fixed;
  bottom: 0.5em;
  right: 1em;
  background-color: var(--pwhite-50);
}

.footer-buffer{
  min-height: 3em;
}

.copyright-msg{
  font-size: 1.25em;
  min-width: 19em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.copyright-msg p {
  text-align: center;
  color: var(--mgrey);
}

.subheader {
  min-height: 3.5em;
}

.visible{
  visibility: visible;
}

.hidden{
  display: none;
}

.header-button {
  background-color: var(--poffwht);
  border-radius: 0.3em;
  border: 0 none;
  height: 3.25em;
  width: 3.75em;
  margin-top: 0.5em;
  margin-right: 0.125em;
  margin-left: 1.5em;
  padding: 0em;
  padding-right: 0.5em;
  cursor: pointer;
  box-shadow: 0.1em 0.1em var(--lgrey-50);  
}

.header-button:hover {
  background-color: var(--pmust);
  box-shadow: 0.15em 0.15em var(--lgrey-50);
}

.good-status {
  color: var(--pgrass);
}

.plural {
  display: inline-block;
}

.non-plural {
  display: none;
}

.capitalize{
  text-transform: capitalize;
}

/*forms for mobile*/
@media screen and (max-width: 450px){
  h1.header-word{
    padding: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0em;
  }
}

details summary{
  cursor: pointer;
}

summary h3 {
  color: var(--dgrey);
  padding-bottom: 0.75em;
  font-size: 1.25em;
}

details h4 {
  margin-left: 0.8em;
  padding-top: 0.5em;
  color: var(--pbeet);
}

details summary > * {
  display: inline;
}

details{
  padding-left: 0.8em;
  padding-bottom: 1em;
  max-width: 50em;
}

.header-word {
  font-size: 2em;
  padding-top: 0.75em;
}

#header-status {
  width: 5em;
}

.header-msg {
  padding-left: 0.75em;
  padding-top: 1em;
  font-style: italic;
  color: var(--mgrey);
}

/*TOOLTIP*/
/*this item's parent container needs position: relative*/
.help-tip{ /*the question mark*/
  position: relative;
  top: 0.2em;
  left: 12em;
  text-align: center;
  background-color: var(--ppaper-50);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  font-size: 1em;
  line-height: 1.5em;
  cursor: default;
}

.help-tip:before{
  content:'?';
  font-weight: bold;
  color:#fff;
}

.help-tip:hover p{
  display:block;
  transform-origin: 100% 0%;

  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadeIn;
}

.help-tip p{    /* The tooltip */
  display: none;
  text-align: left;
  background-color: var(--dgrey);
  padding: 0.5em;
  width: 10em;
  position: absolute;
  border-radius: 3%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  left: -0.25em;
  color: #FFF;
  font-size: 1em;
  line-height: 1.4;
  z-index: 5;
}

.help-tip p:before{ /* The pointer of the tooltip */
  position: absolute;
  content: '';
  width:0;
  height: 0;
  border:6px solid transparent;
  border-bottom-color:var(--dgrey);
  left:10px;
  top:-12px;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% { opacity:0; }
  80% { opacity:0; }
  100% {opacity:100%; }
}

@keyframes fadeIn {
  0% { opacity:0; }
  80% { opacity:0; }
  100% { opacity:100%; }
}

.req-field{
  color: var(--pbeet);
}

span.b {
  font-weight: 700;
  color: black;
}

span.u {
  text-decoration: underline;
}
